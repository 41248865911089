@font-face {
  font-family: "Circular Std";
  src: url("../../assets/fonts/CircularStd-Medium.woff2") format("woff2"),
    url("../../assets/fonts/CircularStd-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("../../assets/fonts/Nunito-ExtraLight.woff2") format("woff2"),
    url("../../assets/fonts/Nunito-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
/* utilities css */
.line-clamp-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.line-clamp-4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.text-black {
  color: #000000;
}
/* utilities css end */
/* navigation */

.restly-header-main-menu ul > li > a {
  color: #fff;
}
.restly-header-main-menu ul > li .sub-menu li a {
  color: #0e1133;
}
.restly-header-main-menu ul > li > a {
  color: #fff;
}
.restly-header-main-menu ul > li .sub-menu li a {
  color: #0e1133;
}
.restly-header-main-menu ul > li > a:hover {
  color: #801f82;
}
.restly-header-main-menu ul > li > a:hover {
  color: #9b2cfa;
}
.restly-header-main-menu ul > li > a {
  color: #fff;
}
.restly-header-main-menu ul > li > a:hover {
  color: #2b70fa;
}
.restly-header-main-menu ul > li .sub-menu li a {
  color: #505056;
}
.header-nav-box.header-nav-box-7
  .restly-header-main-menu
  ul
  > li
  .sub-menu
  li
  a:hover {
  color: #2b70fa;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .restly-header-main-menu {
    display: none;
  }
}
@media (max-width: 767px) {
  .restly-header-main-menu {
    display: none;
  }
}

.restly-header-main-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.restly-header-main-menu ul > li {
  display: inline-block;
  margin-right: 30px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .restly-header-main-menu ul > li {
    margin-right: 14px;
  }
}
.restly-header-main-menu ul > li > a {
  font-size: 15px;
  font-weight: 500;
  color: #2460f7;
  line-height: 45px;
}
.restly-header-main-menu ul > li > a i {
  padding-left: 6px;
}
.restly-header-main-menu ul > li .sub-menu {
  position: absolute;
  left: 0;
  top: 110%;
  width: auto;
  min-width: 80px;
  max-width: 220px;
  background-image: linear-gradient(
    to right top,
    #000000,
    #050002,
    #080004,
    #0b0008,
    #0c000d,
    #0c000d,
    #0c000d,
    #0c000d,
    #0b0008,
    #080004,
    #050002,
    #000000
  );
  opacity: 0;
  visibility: hidden;
  transition: all linear 0.3s;
  z-index: 99;
  -webkit-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  -moz-box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
  list-style-type: none;
  margin: 0;
  padding: 15px 0;
  border-radius: 5px;
  text-align: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .restly-header-main-menu ul > li .sub-menu {
    min-width: 80px;
    max-width: 200px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .restly-header-main-menu ul > li .sub-menu {
    min-width: 80px;
    max-width: 200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .restly-header-main-menu ul > li .sub-menu {
    position: relative;
    width: 100%;
    left: 0;
    top: auto;
    opacity: 1;
    visibility: visible;
    display: none;
    right: auto;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all none ease-out 0s;
    -moz-transition: all none ease-out 0s;
    -ms-transition: all none ease-out 0s;
    -o-transition: all none ease-out 0s;
    transition: all none ease-out 0s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: left;
    border-top: 0;
    transition: 0s;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .restly-header-main-menu ul > li .sub-menu {
    position: relative;
    width: 100%;
    left: 0;
    top: auto;
    opacity: 1;
    visibility: visible;
    right: auto;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transition: all none ease-out 0s;
    -moz-transition: all none ease-out 0s;
    -ms-transition: all none ease-out 0s;
    -o-transition: all none ease-out 0s;
    transition: all none ease-out 0s;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: left;
    border-top: 0;
    transition: 0s;
    padding: 0;
  }
}
.restly-header-main-menu ul > li .sub-menu > li {
  position: relative;
  margin-left: 0;
  display: block;
}
.restly-header-main-menu ul > li .sub-menu > li .sub-menu {
  margin-left: 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .restly-header-main-menu ul > li .sub-menu > li .sub-menu {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .restly-header-main-menu ul > li .sub-menu > li .sub-menu {
    margin-left: 0;
  }
}
.restly-header-main-menu ul > li .sub-menu > li .sub-nav-toggler {
  color: #0e1133;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.restly-header-main-menu ul > li .sub-menu > li a {
  display: block;
  padding: 0px 30px;
  position: relative;
  color: white;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 4px;
  margin: 0 0;
  line-height: 2.5;
  text-align: left;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .restly-header-main-menu ul > li .sub-menu > li a {
    padding: 0 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .restly-header-main-menu ul > li .sub-menu > li a {
    padding: 0 20px;
  }
}
.restly-header-main-menu ul > li .sub-menu > li a i {
  float: right;
  font-size: 16px;
  margin-top: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .restly-header-main-menu ul > li .sub-menu > li a i {
    display: none;
  }
}
@media (max-width: 767px) {
  .restly-header-main-menu ul > li .sub-menu > li a i {
    display: none;
  }
}
.restly-header-main-menu ul > li .sub-menu > li a .sub-nav-toggler i {
  display: inline-block;
}
.restly-header-main-menu ul > li .sub-menu > li a:hover {
  padding-left: 35px;
}
.restly-header-main-menu ul > li .sub-menu > li .sub-menu {
  right: auto;
  left: 100%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .restly-header-main-menu ul > li .sub-menu > li .sub-menu {
    padding-left: 30px;
    -webkit-transition: all 0s ease-out 0s;
    -moz-transition: all 0s ease-out 0s;
    -ms-transition: all 0s ease-out 0s;
    -o-transition: all 0s ease-out 0s;
    transition: all 0s ease-out 0s;
  }
}
@media (max-width: 767px) {
  .restly-header-main-menu ul > li .sub-menu > li .sub-menu {
    padding-left: 30px;
    -webkit-transition: all 0s ease-out 0s;
    -moz-transition: all 0s ease-out 0s;
    -ms-transition: all 0s ease-out 0s;
    -o-transition: all 0s ease-out 0s;
    transition: all 0s ease-out 0s;
  }
}
.restly-header-main-menu ul > li .sub-menu > li .sub-menu li {
  position: relative;
}
.restly-header-main-menu ul > li .sub-menu > li .sub-menu li .sub-menu {
  right: auto;
  left: 100%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.restly-header-main-menu ul > li .sub-menu > li .sub-menu li:hover .sub-menu {
  top: 0%;
  opacity: 1;
  visibility: visible;
}
.restly-header-main-menu ul > li .sub-menu > li:hover .sub-menu {
  top: 0%;
  opacity: 1;
  visibility: visible;
}
.restly-header-main-menu ul > li .sub-menu > li:hover .sub-nav-toggler {
  color: #7cfcfc;
}
.restly-header-main-menu ul > li .sub-menu > li:hover > a {
  color: #3a72ffee;
}
.restly-header-main-menu ul > li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.restly-header-8-area .header-nav-box .restly-header-main-menu ul > li a:hover {
  color: #db0f30;
}
@-webkit-keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
@keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
.restly-header-main-menu.restly-restly-header-two > ul > li {
  padding: 34px 25px;
}
.restly-header-main-menu.restly-restly-header-two ul li a {
  color: white;
}
.main-menu.navbar-expand-lg .restly-header-main-menu > ul > li {
  padding: 34px 17px;
}
.main-menu.navbar-expand-lg .restly-header-main-menu > ul > li > a {
  color: white;
}

.main-menu.navbar-expand-lg
  .restly-header-main-menu.nav-home-five
  > ul
  > li
  > a {
  color: #0e1133;
}
.main-menu.navbar-expand-lg
  .restly-header-main-menu.nav-home-six
  > ul
  > li
  > a {
  color: #0e1133;
}
.main-menu.navbar-expand-lg .restly-header-main-menu.nav-home-six > ul > li {
  padding: 34px 20px;
}
.restly-header-main-menu ul > li > a {
  line-height: 0;
}
.restly-header-main-menu ul > li {
  margin-right: 0;
}
.nav-home-one.restly-header-main-menu ul > li {
  margin-right: 30px;
}
/* navigation end */

/* home one */
.featured-section {
  background-image: url("../../assets/images/feature/feature-bg.jpg");
}
.work-progress-area {
  background-image: url("../../assets/images/background/work-progress.jpg");
}
.main-footer {
  background-image: url("../../assets/images/footer/footer-bg-dots.png");
}
.news-section-two {
  background-image: url("../../assets/images/news/news-bg-two.jpg");
}
/* home one end */
/* home three */
.testimonial-wrap .slick-slide > div {
  margin: 0 15px;
}
.testimonial-item {
  margin: 0 0;
}
.news-section-two.news-section-three {
  background-image: none;
}
.footer-two.footer-home-three .footer-top {
  display: none;
}
.supermargin {
  margin: 0px 0px 0px;
}
/* home three end */

/* about two */

.about-section.r-com-about-section-two .section-title .sub-title {
  font-weight: 500;
  margin-bottom: 20px;
  color: #ffffff;
  display: inline-block;
  margin: 0px 50px 0px;
  transform: translateX(-50px);
  line-height: 33.5px;
}
.about-section.r-com-about-section-two .about-content :is(span, p, h2, li) {
  color: #000000 !important;
}
/* about two end */
/* home 6 */
.testimonial-three-thumbs div > img {
  width: 70px !important;
  height: 70px !important;
  margin: 10px !important;
  cursor: pointer;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  border: 2px solid transparent;
}
.testimonial-three-thumbs .slick-current div > img {
  border-color: #029696 !important;
}
.testimonial-three-thumbs.quomo-testimonial-three-thumbs {
  position: absolute;
  display: flex;
  justify-content: center;
  left: calc(50% - 130px);
}
.testimonial-three-thumbs {
  bottom: 135px;
}
/* home 6 end */
/* home 7  */
.header-seven .main-menu .restly-header-main-menu li a {
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins";
}
.header-seven .main-menu.navbar-expand-lg .restly-header-main-menu > ul > li {
  padding: 15px 15px;
}
.testimonial-four-active .slick-dots {
  position: unset;
}
.testimonial-four-author img {
  width: 110px;
  height: auto;
}
.testimonial-four-active .slick-slide > div {
  margin: 15px 15px;
}
.testimonial-four-item {
  margin: 0;
}
/* home 7 end */
.blog-four .slick-slide > div {
  margin: 15px 15px;
}
.blog-style-four {
  margin: 0;
}
.mobile-header {
  width: 100%;
  height: 60px;
  padding: 10px;
  background: black;
}
.mobile-header .logo-area img {
  width: 100px;
  height: auto;
}
.mobile-header .button-area span {
  color: #000000;
}
/* team page */
.team-section-three.team-page-three .section-title-with-btn {
  display: none;
}
/* team page end */
.header-seven .main-menu.navbar-expand-lg .restly-header-main-menu > ul > li {
  padding: 30px 15px;
}
/* ====================================rtl=========================== */
.direction-rtl .restly-header-main-menu ul > li .sub-menu > li a {
  display: block;
  padding: 0px 30px;
  position: relative;
  color: #505056;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 4px;
  margin: 0 0;
  line-height: 2.5;
  text-align: right;
}
.direction-rtl .restly-header-main-menu ul > li .sub-menu > li:hover > a {
  color: #2b70fa;
}
.direction-rtl .restly-header-main-menu ul > li .sub-menu > li a:hover {
  padding-left: auto;
  padding-right: 35px;
}
.direction-rtl .restly-header-main-menu ul > li .sub-menu > li .sub-menu {
  right: 100%;
  left: auto;
}
.direction-rtl .restly-header-main-menu ul > li .sub-menu {
  left: auto;
  right: 0;
}
.direction-rtl .top-right .office-time {
  margin-right: auto;
  margin-left: 75px;
}
.direction-rtl .nav-outer .menu-btn {
  margin-left: auto;
  margin-right: auto;
}
.direction-rtl .restly-header-main-menu ul > li > a i {
  padding-left: auto;
  padding-right: 6px;
}
.direction-rtl .top-left ul li {
  margin-right: 0;
  margin-left: 50px;
}

.direction-rtl .top-right .office-time i {
  margin-right: 0px;
  margin-left: 10px;
}
.direction-rtl .social-style-one a {
  margin-right: 0px;
  margin-left: 15px;
}
.direction-rtl .list-style-one li:before {
  margin-right: 0;
  margin-left: 15px;
}
.direction-rtl .about-image-shape::before {
  left: auto;
  right: -9px;
}
.direction-rtl .about-image-shape:after {
  left: auto;
  right: 13px;
}
.direction-rtl .feature-item .icon {
  margin-right: 0px;
  margin-left: 25px;
}
.direction-rtl .section-title-with-btn h2 {
  margin-right: 0px;
  margin-left: 25px;
}
.direction-rtl .work-progress-item .icon .progress-step {
  right: auto;
  left: 5px;
}
.direction-rtl .gallery-item .gallery-content {
  left: auto;
  right: 20px;
}
.direction-rtl .post-meta-item li {
  margin: 0 0 5px 30px;
}
.direction-rtl .post-meta-item li i {
  margin-right: 0px;
  margin-left: 10px;
}
.direction-rtl .news-author img {
  margin-left: 15px;
  margin-right: 0;
}
.direction-rtl .list-style-two i {
  margin: 6px 0 0 20px;
}
.direction-rtl .copyright-inner .social-style-one a {
  margin-right: 0;
  margin-left: 33px;
}
.direction-rtl .about-wrap::before {
  right: auto;
  left: 0;
}
.direction-rtl .list-style-three li:before {
  margin-right: 0;
  margin-left: 20px;
}
.direction-rtl .about-two-image::before,
.about-three-image::before {
  left: auto;
  right: -35px;
}
.direction-rtl .learn-more i {
  margin: 2px 8px 0 0;
}
.direction-rtl .learn-more:hover i {
  margin-left: 0px;
  margin-right: 10px;
}
.direction-rtl .gallery-item.style-two .gallery-content {
  padding: 25px 30px 25px 0;
  left: auto;
  right: 0;
}
.direction-rtl .progress-item-two {
  border-right: none;
  border-left: 1px solid rgb(230, 232, 235);
}
.direction-rtl .footer-top .contact-info li {
  border-right: none;
  border-left: 1px solid #223452;
}
.direction-rtl .contact-info li i {
  margin: 5px 0 0 12px;
}
.direction-rtl .footer-top .contact-info li:last-child {
  border-left: none;
}
.direction-rtl .header-three .top-right {
  margin-left: 0;
}
.direction-rtl .mr-25 {
  margin-right: 0;
  margin-left: 25px;
}
.direction-rtl .service-title-area {
  margin-left: 15px;
  margin-right: 0px;
}
.direction-rtl .about-three-image img:last-child {
  margin: -33% 33% 0 0;
}
.direction-rtl .author-description img {
  margin-right: 0;
  margin-left: 22px;
}
.direction-rtl .author-description i {
  margin-left: 0;
  margin-right: auto;
}
.direction-rtl .gallery-carousel-arrow .slick-arrow {
  margin-right: 0;
  margin-left: 22px;
}
.direction-rtl .gallery-item.style-three .gallery-content {
  left: auto;
  right: 30px;
}

.direction-rtl .main-menu .navbar-collapse li {
  float: right;
}
.direction-rtl .main-menu .navbar-collapse li ul li ul {
  left: auto;
  right: 100%;
}
.direction-rtl .mr-15,
.mx-15 {
  margin-right: 0px;
  margin-left: 15px;
}
.direction-rtl .feature-item .feature-btn {
  right: auto;
  left: 30px;
}
.direction-rtl .about-tab li a {
  padding: 0 0 25px 20px;
}
.direction-rtl .about-tab li a i {
  margin-right: 0px;
  margin-left: 20px;
}
.direction-rtl .about-tab li a:before {
  left: auto;
  right: 0;
}
.direction-rtl .team-member-three .member-designation .social-style-one a {
  margin-right: 0px;
  margin-left: 30px;
}
.direction-rtl .pricing-section-three .container::after {
  right: auto;
  left: -100px;
}
.direction-rtl .social-style-two a {
  margin-right: 0;
  margin-left: 7px;
}
.direction-rtl .widget-news-item img {
  margin-right: 0px;
  margin-left: 22px;
}
.direction-rtl
  .team-member-three
  .member-designation
  .social-style-one
  a:last-child,
.direction-rtl .social-style-one a:last-child {
  margin-left: 0;
}
.direction-rtl .hero-five-image {
  right: auto;
  left: 0;
}
.direction-rtl .about-fiver-image .about-bg-circle {
  left: auto;
  right: 0;
}
.direction-rtl .pl-70,
.direction-rtl .px-70 {
  padding-left: 0px;
  padding-right: 70px;
}
.direction-rtl .gallery-item-five .gallery-content .gallery-btn {
  right: auto;
  left: 22px;
}
.direction-rtl .testimonial-two-active .slick-dots li {
  margin-left: 0;
  margin-right: 10px;
}
.direction-rtl .hero-six-images img:nth-child(3) {
  left: auto;
  right: 0;
}
.direction-rtl .hero-six-images img:nth-child(4) {
  right: auto;
  left: 0;
}
.direction-rtl .hero-six-images {
  padding-left: 0;
  padding-right: 5%;
}
.direction-rtl .hero-six-images img:nth-child(2) {
  margin: -35% auto 0 5%;
}
.direction-rtl .service-item-six .icon {
  margin-right: 0;
  margin-left: 20px;
}
.direction-rtl .about-shape-six .about-graph {
  left: auto;
  right: -8%;
}
.direction-rtl .theme-btn i,
.direction-rtl a.theme-btn i {
  margin-left: 0;
  margin-right: 10px;
}
.direction-rtl .customization-images img:nth-child(1) {
  margin-left: 11%;
  margin-right: -9%;
}
.direction-rtl .customization-images img:nth-child(2) {
  margin: -48% auto 0 0;
}
.direction-rtl .customization-content .list-style-three li:before {
  margin-right: 0px;
  margin-left: 10px;
}
.direction-rtl .theme-btn:hover i,
.direction-rtl a.theme-btn:hover i {
  margin-left: -5px;
  margin-right: 15px;
}
.direction-rtl .widget-services li a:after {
  margin-left: 0;
  margin-right: auto;
}
.direction-rtl .widget-title:before {
  left: auto;
  right: 10px;
}
.direction-rtl .widget-title:after {
  left: auto;
  right: 0;
}
.direction-rtl .product-item .image .sale {
  left: auto;
  right: 5px;
}
.direction-rtl .pagination li {
  margin: 0 0 10px 10px;
}
.direction-rtl .widget-product-item > a {
  margin-right: 0;
  margin-left: 15px;
}
.direction-rtl .shop-sidebar .widget-search form input {
  padding-right: 20px;
  padding-left: 0;
}
.direction-rtl .descriptions .add-to-cart input {
  margin-right: 0;
  margin-left: 10px;
}
.direction-rtl .team-profile-content .social-style-two a {
  margin-left: 20px;
  margin-right: 0;
}
.direction-rtl blockquote {
  border-left: none;
  padding-left: 0px;
  padding-right: 30px;
  border-right: 5px solid rgb(16, 76, 186);
}
.direction-rtl .blog-header .social-style-one span,
.blog-header .social-style-two span,
.blog-footer .social-style-one span,
.blog-footer .social-style-two span {
  margin-right: 0;
  margin-left: 15px;
}
.direction-rtl .comment-title::before {
  left: auto;
  right: 10px;
}
.direction-rtl .comment-title::after {
  left: auto;
  right: 0;
}
.direction-rtl .comment-item .author-image {
  margin: 5px 0 20px 30px;
}
.direction-rtl .comment-item.child-comment {
  margin-left: 0;
  margin-right: 55px;
}
.direction-rtl .comment-form .form-group label {
  right: auto;
  left: 15px;
}
.direction-rtl .header-two .logo-outer {
  border-right: none;
  border-left: 1px solid #4e597b;
}
/* .direction-rtl .header-one .header-top {
  padding-left: 230px;
  padding-right: 85px;
} */
.direction-rtl .header-one .logo-outer {
  margin-right: 0px;
  margin-left: 45px;
}
.direction-rtl .direction-rtl .header-one .header-inner {
  padding-right: 0px;
  padding-left: 230px;
}
.direction-rtl .direction-rtl .nav-outer .menu-btn {
  margin-left: 0;
  margin-right: auto;
}
.direction-rtl .direction-rtl .top-right .office-time {
  margin-right: 0px;
  margin-left: 75px;
}
.direction-rtl .direction-rtl .header-two .header-inner {
  padding-left: 70px;
  padding-right: 5px;
}
.direction-rtl .direction-rtl .header-two .header-top {
  padding-left: 70px;
  padding-right: 30px;
}
.direction-rtl .direction-rtl .header-three .main-menu {
  margin-left: 0;
  margin-right: auto;
}
.direction-rtl .direction-rtl .header-three .menu-btn {
  margin-left: 0;
  margin-right: 15px;
}
.direction-rtl .direction-rtl .header-three .top-right .office-time {
  margin-left: 0;
  margin-right: 0;
}
.direction-rtl .header-four .office-time {
  margin-right: 0px;
  margin-left: 40px;
}
.direction-rtl .header-four .menu-btn {
  margin-left: 0px;
  margin-right: 15px;
}
.direction-rtl .header-five .header-inner {
  padding-left: 15px;
  padding-right: 0;
}
.direction-rtl .header-five .header-inner:after {
  right: auto;
  left: 0;
}
.direction-rtl .header-five .menu-btn {
  margin-left: 0px;
  margin-right: 15px;
}
.direction-rtl .header-six .main-menu {
  margin-left: auto;
  margin-right: 50px;
}
.direction-rtl .header-six .menu-btn {
  margin-left: 0px;
  margin-right: 20px;
}
.direction-rtl .hero-content-five.mr-10 {
  margin-left: 10px;
  margin-right: 0px;
}
.direction-rtl .main-menu .navbar-header .navbar-toggle {
  float: left;
}
.direction-rtl .main-menu .navbar-collapse li.dropdown .dropdown-btn {
  right: auto;
  left: 0;
}
.direction-rtl .contact-form-left h2 {
  padding-left: 0;
  padding-right: 30px;
  border-left: none;
  border-right: 5px solid black;
}
.direction-rtl .hero-right-part {
  right: auto;
  left: 0;
}
.direction-rtl .hero-right-part .data-item.one {
  left: auto;
  right: 15%;
}
.direction-rtl .hero-right-part .data-item.two {
  left: auto;
  right: 0;
}
.direction-rtl .hero-right-part .data-item.three {
  left: auto;
  right: 15%;
}
.direction-rtl .hero-right-part .data-item img {
  margin-right: 0;
  margin-left: 20px;
}
.direction-rtl .read-more i {
  float: left;
  margin-left: 0;
  margin-right: 5px;
}
.direction-rtl .read-more:hover i {
  margin-left: 0;
  margin-right: 8px;
}
.direction-rtl .success-item.style-four > i {
  margin-right: 0;
  margin-left: 20px;
}
.direction-rtl .theme-btn.style-eight i,
.theme-btn.style-eight i,
a.theme-btn.style-eight i,
a.theme-btn.style-eight i {
  float: left;
}
.direction-rtl .faq-accordion .toggle-btn {
  margin-left: 0;
  margin-right: 8px;
}
.direction-rtl .pricing-tab a:first-child {
  margin-right: 0;
  margin-left: 45px;
}
.direction-rtl .pricing-tab a:last-child {
  margin-left: 0;
  margin-right: 45px;
}
.direction-rtl .pricing-tab:before {
  left: auto;
  right: 58px;
}
.direction-rtl .pricing-tab:after {
  left: auto;
  right: 88px;
}
.direction-rtl .pricing-tab.for-yearly:after {
  left: auto;
  right: 63px;
}
.direction-rtl .pricing-item-seven .list-style-three li:before {
  margin-right: 0;
  margin-left: 10px;
}
.direction-rtl .testimonial-four-author {
  padding: 0 15px 0 40px;
}
.direction-rtl .testimonial-four-author-designation {
  padding: 45px 10px 15px 0;
}
.direction-rtl .testimonial-four-author::after {
  right: auto;
  left: 40px;
}
.direction-rtl .blog-style-four .blog-four-content .date i {
  margin-right: 0;
  margin-left: 8px;
}
.direction-rtl .footer-six .footer-widget .social-style-two a {
  margin: 0px 0px 10px 10px;
}
.direction-rtl .newsletter-widget form input {
  padding: 5px 20px 5px 5px;
}
.direction-rtl .header-seven .main-menu {
  margin-right: 100px;
  margin-left: auto;
}
.direction-rtl .header-seven .header-number {
  margin-right: 0;
  margin-left: 100px;
}
.direction-rtl .header-number > i {
  margin-right: 0;
  margin-left: 20px;
}
.direction-rtl
  .header-seven
  .main-menu
  .navbar-collapse
  li.dropdown
  .dropdown-btn {
  right: auto;
  left: 2px;
}
.direction-rtl .header-seven .menu-btn {
  margin-left: 0;
  margin-right: 0;
}

.direction-rtl .hero-eight-image {
  margin-right: 0;
  margin-left: -100px;
}
.direction-rtl .home-nine .slick-arrow:first-child {
  margin-right: 0px;
  margin-left: 5px;
}
.direction-rtl .action-item .content .author-wish .author img {
  margin-left: 10px;
  margin-right: 0;
}
.direction-rtl .action-item .content .author-wish .wish i {
  margin-left: 5px;
  margin-right: 0px;
}
.direction-rtl .seller-item .image .check {
  right: auto;
  left: 15%;
}
.direction-rtl .seller-item .number {
  right: auto;
  left: 20px;
}
.direction-rtl .collection-filter li {
  margin-right: 0px;
  margin-left: 20px;
}
.direction-rtl .call-action-four .right-image {
  right: auto;
  left: -10px;
}
.direction-rtl .author-description .designation {
  margin-right: 0px;
  margin-left: 20px;
}
.direction-rtl .testimonial-six-item .author-description img {
  margin-right: 0;
  margin-left: 0;
}
.direction-rtl .testimonial-six-item:before {
  right: auto;
  left: 22%;
}

.direction-rtl .header-eight .header-top {
  padding-left: 0px;
  padding-right: 300px;
}
.direction-rtl .header-eight .top-left li:not(:last-child) {
  padding-right: 0px;
  padding-left: 30px;
  border-right: none;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
.direction-rtl .main-slider-content {
  float: right;
}
[dir="rtl"] .slick-slide {
  float: left;
}
.direction-rtl .header-eight .top-left li i {
  margin-right: 0px;
  margin-left: 10px;
}
.direction-rtl .header-eight .top-right .hotline i,
.direction-rtl .header-eight .top-right .hotline span {
  margin-right: 0px;
  margin-left: 5px;
}
.direction-rtl .header-eight .top-right .hotline {
  margin-right: 0px;
  padding-right: 0px;
  border-right: none;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  padding-left: 30px;
  margin-left: 30px;
}
/* .direction-rtl .main-slider-content .slider-btns .theme-btn:not(:last-child) {
  margin-right: 0px;
  margin-left: 30px;
} */
.direction-rtl .feature-item-three {
  border-right: none;
  border-left: 1px dashed rgba(3, 10, 21, 0.2);
}
.direction-rtl .feature-item-three .icon {
  margin-right: 0px;
  margin-left: 25px;
}
.direction-rtl .about-eight-images img:first-child {
  margin-right: 0px;
  margin-left: 25px;
}
.direction-rtl .about-eight-images .left-content {
  right: auto;
  left: 40px;
}
.direction-rtl .success-item.style-five {
  padding-right: 0px;
  padding-left: 130px;
  border-right: none;
  border-left: 1px dashed rgba(255, 255, 255, 0.2);
}
.direction-rtl .success-item.style-five .icon {
  margin-left: 30px;
  margin-right: 0px;
}
.direction-rtl .protect-left-part {
  margin-left: 0px;
  margin-right: -45px;
  padding-right: 0px;
  padding-left: 20px;
}
.direction-rtl .protect-item .icon {
  margin-right: 0px;
  margin-left: 30px;
}
.direction-rtl .project-item .project-content {
  left: auto;
  right: 20px;
}
.direction-rtl .testimonial-five-item .author-description {
  padding-right: 0px;
  padding-left: 150px;
}
.direction-rtl .testimonial-five-wrap .slick-arrow {
  right: auto;
  left: 70px;
}
.direction-rtl .testimonial-five-wrap .slick-arrow.prev {
  right: auto;
  left: 140px;
}
.direction-rtl .testimonial-five-wrap::before {
  right: auto;
  left: 100px;
}
.direction-rtl .header-eight .navbar-header button {
  margin-left: 0;
  margin-right: auto;
}
.home-eight .direction-rtl .service-item-eight .icon {
  display: inline-block;
}

.home-eight .direction-rtl .main-slider .theme-btn,
.home-eight .direction-rtl .main-slider a.theme-btn {
  flex-direction: row-reverse;
}
.home-eight .direction-rtl .author-description {
  flex-direction: row-reverse;
}
.direction-rtl
  .main-menu.navbar-expand-lg
  .restly-header-main-menu.nav-home-six
  > ul
  > li {
  padding: 34px 10px;
}

.direction-rtl
  :is(.call-action-three, .news-section-five, .testimonial-five, .project-section, .protect-ready-bg, .cournter-section-five, .services-eight, .about-section-eight, .feature-section-three, .main-slider, .news-six-area, .testimonial-six, .call-action-four-area, .collection-category-area, .work-progress-area-five, .collection-section, .actions-section, .hero-section-eight, .footer-call-action, .blog-four, .testimonial-four, .pricing-seven, .faq-page, .solution-place, .about-seven, .hero-section-seven, .contact-page, .blog-details-area, .blog-page-area, .team-profile, .shop-details-area, .shop-page-area, .portfolio-details, .portfolio-section, .service-details, .page-banner, .contact-four, .subscribe-two, .customization-section, .call-to-action-two, .about-section-six, .hero-section-six, .pricing-section-five, .testimonial-section-two, .contact-section-three, .gallery-section-five, .fact-counter-section, .call-to-action, .hero-section-five, .team-section-three, .about-tab-section, .what-we-do, .hero-section-three, .contact-two, .gallery-section-three, .testimonial-section, .about-three, .services-four, .hero-section-two, .main-footer, .news-section-two, .gallery-section-two, .subscribe-section, .work-progress-two, .contact-section, .services-three, .about-two, .services-section-two, .hero-section, .footer-widget-area, .news-section, .gallery-section, .work-progress-area, .featured-section, .about-section, .services-section, .main-header) {
  text-align: right;
}
.home-nine .direction-rtl .slick-arrow:first-child {
  margin-right: 0px;
  margin-left: 5px;
}
.direction-rtl .action-item .content .author-wish .author img {
  margin-left: 10px;
  margin-right: 0;
}
.direction-rtl .action-item .content .author-wish .wish i {
  margin-left: 5px;
  margin-right: 0px;
}
.direction-rtl .seller-item .image .check {
  right: auto;
  left: 15%;
}
.direction-rtl .seller-item .number {
  right: auto;
  left: 20px;
}
.direction-rtl .collection-filter li {
  margin-right: 0px;
  margin-left: 20px;
}
.direction-rtl .call-action-four .right-image {
  right: auto;
  left: -10px;
}
.direction-rtl .author-description .designation {
  margin-right: 0px;
  margin-left: 20px;
}
.direction-rtl .actions-section .action-item .content .author-wish,
.direction-rtl .actions-section .action-item .content .author-wish .author,
.direction-rtl .collection-category-item .title-dots,
.direction-rtl .actions-section .action-item .content .bid-dots {
  flex-direction: row-reverse;
}
.direction-rtl .wish {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

/* ==================layout setting================== */
.layout-settings {
  width: 200px;
  height: 250px;
  position: fixed;
  right: -200px;
  top: calc(50vh - 150px);
  background: white;
  z-index: 999;
  box-shadow: 0px 0px 12px 0px #4c85f083;
  border-radius: 0 0 0 5px;
  transition: all 0.3s cubic-bezier(0.63, 0.27, 1, 0.41);
}
.layout-settings.active {
  right: 0;
}
.layout-settings .heading {
  padding: 20px 10px;
  border-bottom: 1px solid #4c85f083;
}
.layout-settings .heading h1 {
  font-size: 14px;
  margin: 0;
  line-height: 0;
  text-transform: uppercase;
}
.layout-settings .layout-content {
  padding: 10px 10px;
  height: calc(100% - 40px);
}
.layout-settings .layout-content ul li.content-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  direction: ltr;
}
.layout-settings .layout-content ul li.content-item .item-name span {
  font-size: 14px;
  letter-spacing: 1px;
}
.layout-settings .layout-content ul li.content-item .item-button {
  display: inline-flex;
  transition: all 1s ease-in-out;
}
.layout-settings .layout-content ul li.content-item .item-button button {
  width: 44px;
  height: 20px;
  background: #878f9a;
  border-radius: 23px;
  position: relative;
  transition: all 0.5s ease-in-out;
}
.layout-settings .layout-content ul li.content-item .item-button.active button {
  background: #00cc55;
  transition: all 0.5s ease-in-out;
}
.layout-settings .layout-content ul li.content-item .item-button button span {
  width: 17px;
  height: 18px;
  background: white;
  position: absolute;
  left: 1px;
  top: 1px;
  border-radius: 100%;
  transition: all 0.5s ease-in-out;
}
.layout-settings
  .layout-content
  ul
  li.content-item
  .item-button.active
  button
  span {
  left: 26px;
  transition: all 0.5s ease-in-out;
}
.layout-settings .close-btn-wrapper {
  position: absolute;
  width: 40px;
  height: 40px;
  left: -40px;
  top: 0;
  background: #2460f7;
  border-radius: 5px 0 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.layout-settings .close-btn-wrapper span > svg {
  width: 30px;
  height: 30px;
  color: white;
  animation: rotedSettings 3s linear 0s infinite reverse;
}
@keyframes rotedSettings {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* =================================Home Eight==================================== */
.main-menu.navbar-expand-lg
  .r-header-eight.restly-header-main-menu
  > ul
  > li
  > a {
  color: black;
}
.services-eight
  .service-slider-wrap
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  > div {
  margin: 0 15px;
}
.success-item.style-five span span:not(.count-text) {
  display: block;
  font-size: inherit;
}
.success-item .count-text span span {
  font-size: 48px;
}
.success-item .count-text.k:after {
  content: "k";
  display: none;
}
.success-item .count-text.plus:after {
  content: "+";
  display: none;
}
.success-item .count-text.plus span:after {
  content: "+";
}
.success-item .count-text.k span:after {
  content: "k";
}
.testimonial-five-wrap:before {
  z-index: 1;
}
.testimonial-five-wrap .slick-arrow {
  bottom: 38px;
}
/* =================================Home Eight end==================================== */
/* =================================Home nine end==================================== */
.home-nine .hero-content-eight h1 {
  font-size: 58px;
}
.home-nine
  :is(.actions-active, .sellers-active, .collection-category-active)
  .slick-dots {
  display: flex !important;
}
.home-nine
  :is(.actions-active, .sellers-active, .collection-category-active)
  .slick-dots
  li {
  margin: 0;
}
.home-nine
  :is(.actions-active, .sellers-active, .collection-category-active)
  .slick-dots
  li
  button {
  padding: 0;
}
.home-nine
  :is(.actions-active, .sellers-active, .collection-category-active)
  .slick-slide
  > div {
  margin-left: 15px;
  margin-right: 15px;
}
.home-nine
  :is(.actions-active, .sellers-active, .collection-category-active)
  .action-item {
  margin-left: 0;
  margin-right: 0;
}
.home-nine .collection-category-active .collection-category-item {
  margin-left: 0;
  margin-right: 0;
}

.slick-dots li button:before {
  content: "" !important;
}
.home-nine .offcanvas_menu_wrapper {
  background: #002b33;
}
.home-nine .offcanvas_main_menu li a {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  display: block;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ededed;
  color: #ffffff;
  transition: all 0.3s ease-out 0s;
}
.home-nine .layout-settings {
  background: #000000;
}
/* =================================Container CLASS==================================== */

.containertyle {
  background-color: #04080f;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='240' height='240' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23737373' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%2348553F'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}

.containertyle2 {
  background-image: linear-gradient(
    to bottom,
    #000000,
    #050002,
    #080006,
    #09010c,
    #070311,
    #070312,
    #060414,
    #060415,
    #0a0314,
    #0d0213,
    #100111,
    #12000f
  );
}
.containertyle3 {
  background: #0a0a0a;
  border-radius: 11px;
  /* transform: matrix(1, 0, 0, -1, 0, 0); */
}
.containertyle4 {
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23000000'/%3E%3Cstop offset='1' stop-color='%23000000' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23000313'/%3E%3Cstop offset='1' stop-color='%23000313' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23000313'/%3E%3Cstop offset='1' stop-color='%23000313' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23000000'/%3E%3Cstop offset='1' stop-color='%23000000' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23000000'/%3E%3Cstop offset='1' stop-color='%23000000' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2300071E'/%3E%3Cstop offset='1' stop-color='%2300071E' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
.containertyle5 {
  background-image: linear-gradient(
    to right bottom,
    #000000,
    #030001,
    #050004,
    #050007,
    #04010b,
    #04010d,
    #04020e,
    #040210,
    #070210,
    #0b0110,
    #0f0110,
    #12000f
  );
}
.containertyle6 {
  background-color: #12101f;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%2312101F' cx='50' cy='0' r='50'/%3E%3Cg fill='%23131221' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%23131424' cx='50' cy='100' r='50'/%3E%3Cg fill='%23141626' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%23151828' cx='50' cy='200' r='50'/%3E%3Cg fill='%2315192b' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%23161b2d' cx='50' cy='300' r='50'/%3E%3Cg fill='%23161d30' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%23161f32' cx='50' cy='400' r='50'/%3E%3Cg fill='%23172134' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%23172237' cx='50' cy='500' r='50'/%3E%3Cg fill='%23172439' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%2317263c' cx='50' cy='600' r='50'/%3E%3Cg fill='%2317283e' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%23162a41' cx='50' cy='700' r='50'/%3E%3Cg fill='%23162c43' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%23162e45' cx='50' cy='800' r='50'/%3E%3Cg fill='%23153048' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%2314324a' cx='50' cy='900' r='50'/%3E%3Cg fill='%2313344d' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%2312364F' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: contain;
}
.containertyle7 {
  background-color: #e3e3e3;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23f1f1f1'/%3E%3Cstop offset='1' stop-color='%23f1f1f1' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23b3b3b3'/%3E%3Cstop offset='1' stop-color='%23b3b3b3' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23c2c2c2'/%3E%3Cstop offset='1' stop-color='%23c2c2c2' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23E3E3E3'/%3E%3Cstop offset='1' stop-color='%23E3E3E3' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23FFFFFF'/%3E%3Cstop offset='1' stop-color='%23FFFFFF' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23848484'/%3E%3Cstop offset='1' stop-color='%23848484' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
.containertyle8 {
  background-color: #0f0f0f;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%23051f13' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%23051f13' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%2325382e' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%231f3228' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%2344534b' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%2337463e' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%2365706a' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%23515b56' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%23888e8b' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%236c716e' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%23adadad' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%23888888' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
.containertyle9 {
  background: #000000;
  background: -webkit-linear-gradient(to right, #000000, #434343);
  background: linear-gradient(to right, #000000, #434343);
}
.containertyle10 {
  background-color: #12101f;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'%3E%3Cpolygon fill='%2312364f' points='957 450 539 900 1396 900'/%3E%3Cpolygon fill='%2320dad8' points='957 450 872.9 900 1396 900'/%3E%3Cpolygon fill='%23143f5b' points='-60 900 398 662 816 900'/%3E%3Cpolygon fill='%2300b7c5' points='337 900 398 662 816 900'/%3E%3Cpolygon fill='%23154966' points='1203 546 1552 900 876 900'/%3E%3Cpolygon fill='%230094ac' points='1203 546 1552 900 1162 900'/%3E%3Cpolygon fill='%23165372' points='641 695 886 900 367 900'/%3E%3Cpolygon fill='%23007390' points='587 900 641 695 886 900'/%3E%3Cpolygon fill='%23155d7e' points='1710 900 1401 632 1096 900'/%3E%3Cpolygon fill='%230d5370' points='1710 900 1401 632 1365 900'/%3E%3Cpolygon fill='%2314688a' points='1210 900 971 687 725 900'/%3E%3Cpolygon fill='%2312364f' points='943 900 1210 900 971 687'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}
.containertyle11 {
  background-image: linear-gradient(
    to top,
    #12000f,
    #0e010f,
    #0b010f,
    #07020e,
    #04020c,
    #04020a,
    #040108,
    #030106,
    #030104,
    #030002,
    #020001,
    #000000
  );
}

.containertyle12 {
  background-color: #12101f;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%2312364F' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%2314688A'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}
.containertyle13 {
  background-image: linear-gradient(
    to bottom,
    #01030d,
    #03020a,
    #040107,
    #040004,
    #030002,
    #040002,
    #060002,
    #070002,
    #0b0004,
    #0e0007,
    #11000b,
    #12000f
  );
}
.containertyle14 {
  background-image: radial-gradient(
    circle,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e
  );
}
.containertyle15 {
  background-image: linear-gradient(
    to top,
    #000000,
    #050002,
    #080006,
    #09010c,
    #070311,
    #070312,
    #060414,
    #060415,
    #0a0314,
    #0d0213,
    #100111,
    #12000f
  );
}
.containertyle16 {
  background-image: linear-gradient(
    to bottom,
    #000000,
    #000000,
    #000000,
    #000000,
    #000000,
    #010000,
    #030001,
    #040002,
    #060005,
    #060109,
    #04020e,
    #010411
  );
}
.containertyle17 {
  background-image: radial-gradient(
    circle,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e
  );
}
.containertyle18 {
  background-image: linear-gradient(
    to top,
    #000000,
    #050002,
    #080006,
    #09010c,
    #070311,
    #070312,
    #060414,
    #060415,
    #0a0314,
    #0d0213,
    #100111,
    #12000f
  );
}
.containertyle19 {
  background-image: linear-gradient(
    to bottom,
    #000000,
    #000000,
    #000000,
    #000000,
    #000000,
    #010000,
    #030001,
    #040002,
    #060005,
    #060109,
    #04020e,
    #010411
  );
}
.containertyle20 {
  background-image: radial-gradient(
    circle,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e,
    #00071e
  );
}
.containertyle21 {
  background-image: linear-gradient(
    to left bottom,
    #000000,
    #000000,
    #000000,
    #000000,
    #000000,
    #030001,
    #060003,
    #090005,
    #0d000c,
    #0d0113,
    #090419,
    #00071e
  );
}
.containertyle22 {
  background-image: linear-gradient(
    to left bottom,
    #000000,
    #000000,
    #000000,
    #000000,
    #000000,
    #030001,
    #060003,
    #090005,
    #0d000c,
    #0d0113,
    #090419,
    #00071e
  );
}
.containertyle23 {
  background-image: linear-gradient(
    to top,
    #12000f,
    #0e010f,
    #0b010f,
    #07020e,
    #04020c,
    #04020a,
    #040108,
    #030106,
    #030104,
    #030002,
    #020001,
    #000000
  );
}
.imagen_fondo {
  background-image: url(https://api.preview.dspro.tech/uploads/SERVICES_BACKGROUND_1_6c5f56efd6.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 20em;
}
/* =================================Font==================================== */
.colorelection {
  color: white;
}
.colorelection-two {
  color: white;
  font-size: 30px;
}
.fontelection {
  font-family: "Poppins";
}
.fontelection2 {
  font-family: "Poppins";
  font-style: normal;
}
.fontelection3 {
  font-family: "Poppins";
  font-style: normal;
}
.fontelection4 {
  font-family: "Poppins";
  font-size: 20px;
}
.fontelection5 {
  font-family: "Poppins";
  font-style: normal;
}
.fontelection6 {
  font-size: 17px;
  font-weight: bold;
  line-height: 1;
  color: white;
}
.fontelection7 {
  font-size: 16px;
  color: white;
}
.fontelection8 {
  font-size: 16px;
  color: white;
}
.fontelection9 {
  font-size: 16px;
  font-weight: bold;
  color: white;
}
.fontelection10 {
  margin-top: -6px;

  text-align: left;
  font-weight: bold;
}
.fontelection11 {
  font-size: 16px;
  text-align: left;
  margin-top: -27px;
  line-height: 1.5;
}
.fontelection12 {
  text-align: center;
  margin-top: -135px;
  color: white;
  line-height: 1.3;
}
.fontelection13 {
  margin-left: 120px;
  font-size: 16px;
  text-align: left;
}
.fontelection14 {
  font-family: "Poppins";
  margin-bottom: 14px;
}
.fontelection15 {
  font-family: "Poppins";
  font-style: normal;
  font-size: 40px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.fontelection16 {
  transform: translateX(48px);
  text-align: center;
}
.fontsize {
  font-size: 15px;
}
.fontsize2 {
  font-size: 20px;
}
.fontsize3 {
  font-size: 16px;
}
.fontsize4 {
  font-size: 19px;
  font-family: "Poppins";
  font-style: normal;
}
.fontsize5 {
  font-size: 15px;
  margin-top: 10px;
}
.fontsize6 {
  font-size: 13px;
  margin-left: -14px;
}
.fontelectiontyle {
  width: 80px;
  height: 0;
  border: 1px solid #c4c4c4;
  margin: 0px 3px;
  display: inline-block;
  transform: translateY(45px);
  margin-top: -24px;
}
.fontelectiontyle2 {
  font-size: 18px;
}
.fontelectiontyle3 {
  font-size: 18px;
  font-family: "Poppins";
  text-align: left;
  margin-left: 20px;
  margin-top: -20px;
}
.fontelectiontyle4 {
  font-size: 18px;
}
.fontcolor {
  color: black;
}
.font-color-Two {
  color: white;
}

/* =================================Lineheight==================================== */
.line-height1 {
  line-height: 0;
  color: white;
  text-align: initial;
}
.line-height2 {
  line-height: 1;
}
.line-height3 {
  line-height: 1;
}

#map {
  height: 400px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}
/* =================================Rectangle==================================== */
.rectangle {
  width: 349px;
  height: 356px;
  position: relative;
  max-width: 81%;
  display: flex;
  vertical-align: middle;
  border-style: none;
  background: #0a0a0a;
  border-radius: 11px;
  z-index: -1;
  margin-left: 0px;
  transform: translateY(40px);
  left: 70px;
}

/* =================================Margin==================================== */
.marginstops {
  margin-top: -46px;
  margin-left: 95px;
}
.marginstops2 {
  margin-top: 8px;
}
.marginstops3 {
  margin-top: 20px;
}
.marginsleft {
  margin-left: -30px;
}

.marginstops4 {
  margin-top: -290px;
}
.marginstops5 {
  margin: 0px -6px 0px;
}
.marginstops6 {
  margin: -7px 0px -1px;
}
.marginstops7 {
  margin: -9px -4px 0px;
}
.marginstops8 {
  margin-top: 130px;
}
.marginstops9 {
  width: 73%;
  margin-bottom: 37px;
}
.marginstops10 {
  margin-left: -15px;
}
.marginstops11 {
  margin-top: 40px;
  line-height: 1.5;
}
.marginstops12 {
  margin-left: -30px;
}
.marginstops13 {
  margin-top: -35px;
  line-height: 1.5;
}

.marginstops14 {
  margin-left: -150px;
}
.marginstops15 {
  margin-top: -15px;
  line-height: 1.1;
}
.marginstops16 {
  margin-top: -15px;
}
.marginstops17 {
  margin-left: -18px;
}
.marginstops18 {
  transform: translateY(58px);
  margin-left: -225px;
}
.marginstops19 {
  margin-top: 10px;
}
.marginstops20 {
  margin-top: 10px;
}
.marginstops21 {
  margin-top: -35px;
  line-height: 1.5;
  /* margin: 0px 204px 39px 200px; */
}
.marginstops22 {
  line-height: 1.2;
}

/* =================================Lines==================================== */
/* Thick red border */
hr.lineup {
  border: 1px solid rgb(255, 255, 255);
}
.line2 {
  border-left: 1px solid rgb(255, 255, 255);
  height: 100px;
}
hr.lineup3 {
  border: 1px solid #2460f7;
}
hr.lineup4 {
  border: 1px solid rgb(255, 255, 255);
}
/* =================================Images==================================== */
.imagestyle {
  border-radius: 0px 244.5px 264px 0px;
}
.size-style {
  background-image: linear-gradient(
    to right top,
    #000000,
    #0a0003,
    #110007,
    #16000d,
    #190012,
    #190012,
    #190012,
    #190012,
    #16000d,
    #110007,
    #0a0003,
    #000000
  );
}

/* =================================Columns==================================== */
.column {
  float: left;
  width: 58%;
}
.column2 {
  float: left;
  width: 43%;
}

/* Clear floats after the columns */
.row2:after {
  content: "";
  display: table;
  clear: both;
}
.row2 {
  display: flex;
}

.column {
  flex: 58%;
}
.column2 {
  flex: 50%;
}
/* =================================Border==================================== */
.border-flag {
  border-radius: 50%;
}
.border-flag-two {
  transform: translateX(-3px);
}
.border-flag-three {
  border-radius: 50%;
  margin-top: 8px;
}
.border-flag-four {
  border-radius: 50%;
  margin-left: 8px;
  background: black;
}
/* =================================Center==================================== */
.center2 {
  display: flex;
  justify-content: center;
}
.center3 {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-left: 100%;
}
.center4 {
  display: flex;

  text-align: center;
}
.center5 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center6 {
  width: 418px;
  height: 258px;

  text-align: center;
}
.center7 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 11px;
  width: 50%;
}
.center8 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 11px;
  width: 50%;
}
.center9 {
  display: none;
}
.space1 {
  margin-bottom: 90px;
}
.space-two {
  margin-left: 5px;
}
.space-three {
  margin-top: 5px;
}
.space-four {
  align-items: center;
}
.space-five {
  transform: translateX(-4px);
}
.space-six {
  transform: translateX(1px);
}

/* =================================Padding==================================== */
.footerajust {
  display: flex;
  margin-top: 290px;

  text-align: left;
}
.footerajust2 {
  display: flex;
  margin-top: -30px;
}
.footerajust3 {
  display: flex;
  transform: translateX(57px);
  margin: 43px -15px 0px;
}
/* .footerajust4 {
  transform: translateY(15px);
} */
.footerajust5 {
  margin-top: 105px;
  font-family: "Poppins";
  margin-left: 25px;
}
.footerajust6 {
  transform: translateX(9px);
}
.footertext {
  font-size: 20px;
  color: #828282;
  font-weight: bold;
}

/* =================================Botton==================================== */
.btn-whatsapp {
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 110px;
  right: 11px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}
.btn-whatsapp5 {
  position: fixed;
  width: 30px;
  height: 30px;
}
.btn-whatsapp2 {
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 56px;
  right: 11px;
  background-color: #e71616;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.fab {
  background-color: transparent;
  height: 64px;
  width: 64px;
  border-radius: 32px;
  transition: height 300ms;
  transition-timing-function: ease;
  position: absolute;
  right: 20px;
  bottom: 50px;
  text-align: center;
  overflow: hidden;
  position: fixed;
}

.fab:hover {
  height: 344px;
}

.mainop {
  margin: auto;
  width: 55px;
  height: 55px;
  position: fixed;
  bottom: 48px;
  right: 3px;
  transition: transform 300ms;
  background-color: #2460f7;
  border-radius: 32px;
  z-index: 6;
  transform: translateX(-20px);
}

.mainopShadow {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  position: absolute;
  right: 50px;
  bottom: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.mainop i {
  margin-top: 16px;
  font-size: 32px;
  color: #fff;
}

.minifab {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  z-index: 5;
  float: left;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 5px;
  background-color: blue;
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transform: translateY(167px);
}

.minifab:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.minifabIcon {
  height: 24px;
  width: 24px;
  margin-top: 12px;
}

.op1 {
  background-color: #2f82fc;
}

.op2 {
  background-color: #0f9d58;
}

.op3 {
  background-color: #fb0;
}

.op4 {
  background-color: #dd5145;
}

.op5 {
  background-color: #673ab7;
}

body {
  background-color: white;
}

.text-box {
  margin-left: 44vw;
  margin-top: 42vh;
}

.btn:link,
.btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 40px;
  display: inline-block;
  border-radius: 100px;
  transition: all 0.2s;
  position: absolute;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btn-white {
  background-color: #fff;
  color: #777;
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.btn-white::after {
  background-color: #fff;
}

.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-animated {
  animation: moveInBottom 5s ease-out;
  animation-fill-mode: backwards;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.loader-wrapper {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    #000000,
    #000000,
    #000000,
    #000000,
    #000000,
    #010000,
    #030001,
    #040002,
    #060005,
    #060109,
    #04020e,
    #000411
  );
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  z-index: 1031;
  top: 0;
}
.loader {
  width: 32px;
  height: 90px;
  display: block;
  margin: 20px auto;
  position: relative;
  border-radius: 50% 50% 0 0;
  border-bottom: 10px solid #ff3d00;
  background-color: #fff;
  background-image: radial-gradient(
      ellipse at center,
      #fff 34%,
      #ff3d00 35%,
      #ff3d00 54%,
      #fff 55%
    ),
    linear-gradient(#ff3d00 10px, transparent 0);
  background-size: 28px 28px;
  background-position: center 20px, center 2px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  animation: animloaderBack 1s linear infinite alternate;
}
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 64px;
  height: 44px;
  border-radius: 50%;
  box-shadow: 0px 15px #ff3d00 inset;
  top: 67px;
}
.loader::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 34px;
  height: 34px;
  top: 112%;
  background: radial-gradient(
    ellipse at center,
    #ffdf00 8%,
    rgba(249, 62, 0, 0.6) 24%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 50% 50% 0;
  background-repeat: no-repeat;
  background-position: -44px -44px;
  background-size: 100px 100px;
  box-shadow: 4px 4px 12px 0px rgba(255, 61, 0, 0.5);
  box-sizing: border-box;
  animation: animloader 1s linear infinite alternate;
}

@keyframes animloaderBack {
  0%,
  30%,
  70% {
    transform: translateY(0px);
  }
  20%,
  40%,
  100% {
    transform: translateY(-5px);
  }
}

@keyframes animloader {
  0% {
    box-shadow: 4px 4px 12px 2px rgba(255, 61, 0, 0.75);
    width: 34px;
    height: 34px;
    background-position: -44px -44px;
    background-size: 100px 100px;
  }
  100% {
    box-shadow: 2px 2px 8px 0px rgba(255, 61, 0, 0.5);
    width: 30px;
    height: 28px;
    background-position: -36px -36px;
    background-size: 80px 80px;
  }
}

/* =================================end Botton==================================== */

/* =================================Transition Style==================================== */
.boton3 {
  color: #000709 !important;

  padding: 0.5em 1.2em;
  background: rgba(0, 0, 0, 0);
  border: 2px solid;

  transition: all 1s ease;
  position: relative;
}
.boton3:hover {
  background: #000000;
  color: #fff !important;
  text-shadow: 1px 1px 6px #fff;
}

.boton4 {
  color: #000709 !important;

  padding: 0.5em 1.2em;
  background: rgba(0, 0, 0, 0);
  border: 2px solid;

  transition: all 1s ease;
  position: relative;
}
.boton4:hover {
  background: #000000;
  color: rgb(0, 0, 0) !important;
  text-shadow: 1px 1px 6px rgb(255, 255, 255);
}

/* =================================Home nine end==================================== */

/* =================================rtl responsive==================================== */
@media only screen and (min-width: 1501px) {
  .direction-rtl .hero-six-images {
    margin-right: auto;
    margin-left: -175px;
  }
}
@media only screen and (min-width: 1501px) {
  .direction-rtl .gallery-three-wrap {
    left: auto;
    right: 20%;
  }
  .direction-rtl .hero-six-images {
    margin-right: 0;
    margin-left: -175px;
  }
  .direction-rtl .header-five .direction-rtl .header-top-wrap {
    margin-right: 0px;
    margin-left: 40px;
  }
  .direction-rtl .header-five .header-top {
    margin-right: 0;
    margin-left: -20px;
    padding-left: 0;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 1500px) {
  .direction-rtl .top-right .office-time {
    margin-right: 0;
    margin-left: 15px;
  }
  .direction-rtl .top-left ul li {
    margin-left: 15px;
  }
  .direction-rtl .header-one .header-inner,
  .direction-rtl .header-one .header-top {
    padding-left: 85px;
  }
  .direction-rtl .header-two .header-inner {
    padding: 0 15px 0 30px;
  }
  .direction-rtl .header-two .header-top {
    padding-left: 30px;
  }
}
@media only screen and (max-width: 1199px) {
  .direction-rtl .header-one .header-inner,
  .direction-rtl .header-one .header-top {
    padding-right: 0;
    padding-left: 25px;
  }
  .direction-rtl .header-seven .main-menu {
    margin-right: 0px;
  }
  .direction-rtl .hero-eight-image {
    margin-left: 0;
  }
  .direction-rtl .success-item.style-five {
    padding-right: 0px;
    padding-left: 0;
    border-right: none;
    border-left: none;
  }
}
@media (min-width: 992px) {
  .direction-rtl .text-lg-right {
    text-align: left !important;
  }
}

@media only screen and (max-width: 991px) {
  .direction-rtl .main-menu .navbar-collapse li {
    float: none;
  }
  .direction-rtl .header-eight .header-top {
    padding-right: 0;
  }
  .direction-rtl
    .header-eight
    .main-menu
    .navbar-collapse
    .navigation
    > li
    > .dropdown-btn {
    margin-left: 0px;
    margin-right: 3px;
  }
  .direction-rtl .protect-left-part {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header-three .top-right {
    margin-left: auto;
  }
}
@media only screen and (max-width: 575px) {
  .direction-rtl .testimonial-five-wrap .slick-arrow.prev {
    left: 75px;
  }
  .direction-rtl .testimonial-five-wrap .slick-arrow {
    left: 25px;
  }
}
@media only screen and (max-width: 480px) {
  .direction-rtl .about-eight-images img:first-child {
    margin-left: 0px;
  }
}

.language-submenu {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.language-button {
  width: 100%;
  padding: 10px;
  text-align: left;
  background: none;
  border: none;
  color: inherit;
  font-size: inherit;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.language-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
  .language-button {
    padding: 15px 10px;
  }
}
